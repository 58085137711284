import { type FC } from 'react';
import { Progress } from 'flowbite-react';

type Props = {
  title: string;
  keyIndicator: {
    currentMonth: number;
    prevMonth: number;
    monthOnMonth: number;
    prevYearMonth: number;
    yearOnYear: number;
  };
};

const KeyIndicatorWithProgressBar: FC<Props> = ({ title, keyIndicator }) => {
  return (
    <>
      <h4 className="text-lg font-bold dark:text-white">{title}</h4>
      <div className="ml-4 mb-6">
        <div className="mt-2">{keyIndicator.currentMonth}</div>
        <h4 className="mt-3">
          前月比({keyIndicator.prevMonth}): {keyIndicator.monthOnMonth || 0} %
        </h4>
        <Progress
          progress={keyIndicator.monthOnMonth}
          color={keyIndicator.monthOnMonth > 100 ? 'lime' : 'red'}
          className="mt-2"
        />
        <h4 className="mt-3">
          前年同月比({keyIndicator.prevYearMonth}):{' '}
          {keyIndicator.yearOnYear || 0} %
        </h4>
        <Progress
          progress={keyIndicator.yearOnYear}
          color={keyIndicator.yearOnYear > 100 ? 'lime' : 'red'}
          className="mt-2"
        />
      </div>
    </>
  );
};

export default KeyIndicatorWithProgressBar;
